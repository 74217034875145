<template>
    <div class="more_List">
        <div class="mroe_content">
            <div class="more_warp">
                <div class="crumbs">
                    <span>公司首页 | </span> <span>搜索:{{modular}}</span>
                </div>
                <!-- 列表区域 -->
                <div class="more_details">
                    <a-spin :spinning="loading">
                        <ul class="details_ul"> 
                            <li class="details_li" v-for="list in detailsList" :key="list.id" @click="$router.push({path:'details', query:{ id: list.id }})">
                                <span class="sign"></span>
                                <span class="title">{{list.pressTitle}}</span>
                                <span class="pressColumn">{{list.pressColumn}}</span>
                                <span class="author">{{ list.writer}}</span>
                                <span class="time">{{list.releasetime}}</span>
                            </li>   
                        </ul>
                        <div class="pagination">
                            <a-pagination size="small" 
                                :current="current"
                                :show-total="(total, range) => `第${range[0]} - ${range[1]}条,  共 ${total}条`" 
                                :total="total"
                                show-size-changer show-quick-jumper
                                @change="onChange"
                                @showSizeChange="sizeChange"
                                 />
                        </div>
                    </a-spin>
                    
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { getAction } from '@/api/manage'

export default {
    data() {
        return {
            detailsList: [],
            loading: false,
            /* 分页参数 */
            ipagination:{
                current: 1,
                pageSize: 10,
                pageSizeOptions: ['10', '20', '30'],
                showTotal: (total, range) => {
                return range[0] + "-" + range[1] + " 共" + total + "条"
                },
                showQuickJumper: true,
                showSizeChanger: true,
                total: 0
            },
            total: 0,
            size: 10,
            page: 1,
            current: 1,
        }

    },
    computed: {
      modular() {
        return this.$route.query.name
      }
    },
    watch: {
      modular: {
      immediate: true,
      handler: function(newval) {
        console.log('newval: ', newval);
        this.getList()
      }
    }
    },
    created() {
        this.getList()
    },
    methods: {
        getList() {
            console.log(this.modular)
            let formData = {}
            formData = {
              presssTatus:'已发布',
              column:'releasetime',
              order:'desc',
              pressTitle:'*'+this.modular+'*'
            }
            this.loading = true
            getAction('/mesnac/zmap/zmaPrescenter/list',{ pageNo: this.page, pageSize: this.size, ...formData }).then(res => {
                if (res.success) {
                    this.detailsList = res.result.records
                    this.loading = false
                    this.total = res.result.total
                }
            })
        },
        onChange(pageNumber) {
            console.log('pageNumber: ', pageNumber);
            this.page  = pageNumber
            this.current = pageNumber
            this.getList()
        },
        sizeChange (current, size) {
            console.log('current: ', current);
            console.log('size: ', size);
            this.size = size
            this.page = 1
            this.current = 1
            this.getList()
        }
    }
}
</script>

<style lang="less" scoped>
.more_List{
    width: 100%;
    // height: 1250px;
    padding-top: 25px;
    background: url("~@/assets/img/home_bg.png") no-repeat top center / cover;
    .mroe_content{
        width: 1500px;
        margin: 0 auto;
        background: #fff;
        .more_warp{
            padding-top: 30px;
            padding-bottom: 50px;
            min-height: 800px;
            .crumbs{
                margin-left: 40px;
                font-size: 14px;
                color: #101010 10000%;
            }
            .more_details{
                margin-top: 35px;
                padding: 0px 45px 0px 55px;
                .details_ul{
                    list-style: none;
                    .details_li{
                        cursor: pointer;
                        border-bottom: 1px solid #bbb;
                        height: 64px;
                        line-height: 64px;
                        font-size: 16px;
                        color: #101010 10000%;
                        .sign{
                            display: inline-block;
                            width: 10px;
                            height: 10px;
                            background: #BC0039 10000%;
                            border-radius: 50%;
                            vertical-align: top;
                            margin-right: 20px;
                            margin-top: 26px;
                        }
                        .title{
                            display: inline-block;
                            width: 800px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                        .pressColumn{
                          display: inline-block;
                          width: 120px;
                          text-align: center;
                          vertical-align: top;
                        }
                        .author{
                            display: inline-block;
                            margin-right: 60px;
                            margin-left: 60px;
                            text-align: center;
                            width: 120px;
                            vertical-align: top;
                        }
                        .time{
                            vertical-align: top;
                            text-align: right;
                            display: inline-block;
                            width: 150px;
                        }
                    }
                }
                .pagination{
                    text-align: right;
                    /deep/.ant-pagination {
                        .ant-pagination-next, .ant-pagination-prev {
                            .ant-pagination-item-link{
                                &:hover{
                                    color: #BC0039;
                                }
                            }
                        }
                        .ant-pagination-next:focus .ant-pagination-item-link{
                            color: #BC0039;
                        }
                        .ant-pagination-item{
                            a:hover{
                                color: #BC0039;
                            }
                        }
                        .ant-pagination-item-active{
                            border-color: #BC0039;
                            a{color: #BC0039;}
                        }
                    }
                }
            }
        }
    }
}
</style>


